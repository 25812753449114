exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-design-jsx": () => import("./../../../src/pages/design.jsx" /* webpackChunkName: "component---src-pages-design-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-jsx": () => import("./../../../src/pages/photos.jsx" /* webpackChunkName: "component---src-pages-photos-jsx" */),
  "component---src-pages-photos-photo-blog-yaml-web-path-jsx": () => import("./../../../src/pages/photos/{photoBlogYaml.web_path}.jsx" /* webpackChunkName: "component---src-pages-photos-photo-blog-yaml-web-path-jsx" */),
  "component---src-pages-posts-big-data-cup-big-data-cup-md": () => import("./../../../src/pages/posts/big_data_cup/big_data_cup.md" /* webpackChunkName: "component---src-pages-posts-big-data-cup-big-data-cup-md" */),
  "component---src-pages-posts-crime-crime-mdx": () => import("./../../../src/pages/posts/crime/crime.mdx" /* webpackChunkName: "component---src-pages-posts-crime-crime-mdx" */),
  "component---src-pages-posts-datafest-2021-data-fest-viz-jsx": () => import("./../../../src/pages/posts/datafest_2021/DataFestViz.jsx" /* webpackChunkName: "component---src-pages-posts-datafest-2021-data-fest-viz-jsx" */),
  "component---src-pages-posts-datafest-2021-datafest-2021-mdx": () => import("./../../../src/pages/posts/datafest_2021/datafest_2021.mdx" /* webpackChunkName: "component---src-pages-posts-datafest-2021-datafest-2021-mdx" */),
  "component---src-pages-posts-drobe-drobe-mdx": () => import("./../../../src/pages/posts/drobe/drobe.mdx" /* webpackChunkName: "component---src-pages-posts-drobe-drobe-mdx" */),
  "component---src-pages-posts-kepler-kepler-md": () => import("./../../../src/pages/posts/kepler/kepler.md" /* webpackChunkName: "component---src-pages-posts-kepler-kepler-md" */),
  "component---src-pages-posts-milb-injuries-milb-injuries-mdx": () => import("./../../../src/pages/posts/milb_injuries/milb_injuries.mdx" /* webpackChunkName: "component---src-pages-posts-milb-injuries-milb-injuries-mdx" */),
  "component---src-pages-posts-moodspace-moodspace-mdx": () => import("./../../../src/pages/posts/moodspace/moodspace.mdx" /* webpackChunkName: "component---src-pages-posts-moodspace-moodspace-mdx" */),
  "component---src-pages-posts-professor-project-professor-project-mdx": () => import("./../../../src/pages/posts/professor_project/professor_project.mdx" /* webpackChunkName: "component---src-pages-posts-professor-project-professor-project-mdx" */),
  "component---src-pages-posts-ticket-prices-ticket-prices-mdx": () => import("./../../../src/pages/posts/ticket_prices/ticket_prices.mdx" /* webpackChunkName: "component---src-pages-posts-ticket-prices-ticket-prices-mdx" */),
  "component---src-pages-posts-ufc-ufc-mdx": () => import("./../../../src/pages/posts/ufc/ufc.mdx" /* webpackChunkName: "component---src-pages-posts-ufc-ufc-mdx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-big-data-cup-big-data-cup-md": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/big_data_cup/big_data_cup.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-big-data-cup-big-data-cup-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-crime-crime-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/crime/crime.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-crime-crime-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-datafest-2021-datafest-2021-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/datafest_2021/datafest_2021.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-datafest-2021-datafest-2021-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-drobe-drobe-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/drobe/drobe.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-drobe-drobe-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-kepler-kepler-md": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/kepler/kepler.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-kepler-kepler-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-milb-injuries-milb-injuries-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/milb_injuries/milb_injuries.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-milb-injuries-milb-injuries-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-moodspace-moodspace-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/moodspace/moodspace.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-moodspace-moodspace-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-professor-project-professor-project-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/professor_project/professor_project.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-professor-project-professor-project-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-ticket-prices-ticket-prices-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/ticket_prices/ticket_prices.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-ticket-prices-ticket-prices-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-ufc-ufc-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/src/pages/posts/ufc/ufc.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-pages-posts-ufc-ufc-mdx" */)
}

